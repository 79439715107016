<template>
  <dan-toc />
</template>
<script>
import DanToc from '@/modules/danh-muc/components/pages/danh-muc/dung-chung/DanToc.vue'

export default {
  components: {
    DanToc,
  },
}
</script>
