<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      :actions="['createBtn', 'editorBtn', 'deleteBtn', 'reFresh']"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
      @update-item="updateCommom"
      @delete-item="deleteCommomTable"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="payload.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
import XoaForm from '@/modules/danh-muc/components/form/XoaForm.vue'
import DanTocForm from '@/modules/danh-muc/components/form/DanTocForm.vue'
import { BCard, BButton } from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/danh-muc/components/common/ActionBtn.vue'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { checkStatus, compareObjects } from '@/utils/index'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'
import { checDeleteMultiple } from '../../../../utils/common-utils'

extend('required', required)

export default {
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    GoodTable,
    BButton,
    DanTocForm,
    XoaForm,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới dân tộc',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã dân tộc',
          field: 'maDanToc',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên dân tộc',
          field: 'tenDanToc',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          tdClass: 'text-left',
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center',
          thClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      selectedRows: [],
      optionsTrangThai: STATUS,
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        maDanToc: null,
        tenDanToc: null,
        status: null,
        ghiChu: null,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        maDanToc: null,
        tenDanToc: null,
        status: true,
        ghiChu: null,
      },
      beginObject: {},
      isLoading: false,
      isShow: false,
      isShowFooter: false,
      size: null,
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.DAN_TOC.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.DAN_TOC.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.DAN_TOC.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataDanToc()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    getDataDanToc() {
      this.isLoading = false
      try {
        this.$axios
          .post(END_POINTS.DAN_TOC.DS, this.payload, false)
          .then(res => {
            if (res.data?.succeeded) {
              this.rows = res.data.data
              this.total = res.data.totalCount
            }
          })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataDanToc()
      })
    },
    refreshPage() {
      this.payload = {
        maDanToc: null,
        tenDanToc: null,
        status: null,
        ghiChu: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.getDataDanToc()
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới dân tộc'
      this.componentName = 'DanTocForm'
      this.size = 'lg'
      this.dataForm = {
        maDanToc: null,
        tenDanToc: null,
        ghiChu: null,
        status: true,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedRows = data
      this.selectedNumber = data.length
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedRows.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.DAN_TOC.XOA, payload, false).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedRows.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataDanToc()
            this.$refs.commonModal.hideModal()
            checDeleteMultiple(this, res.data)
          }
        })
      } else if (this.componentName === 'DanTocForm') {
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.DAN_TOC.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataDanToc()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        } else {
          this.$axios.post(END_POINTS.DAN_TOC.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataDanToc()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật dân tộc'
      this.componentName = 'DanTocForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        maDanToc: data.maDanToc,
        tenDanToc: data.tenDanToc,
        ghiChu: data.ghiChu,
        status: data.status,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa dân tộc đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa dân tộc đã chọn?'
      this.componentName = 'XoaForm'
      this.size = 'sm'
      this.selectedRows = [data]
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.DAN_TOC.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        maDanToc: data.maDanToc ? data.maDanToc.replace(/\s+/g, ' ').trim() : '',
        tenDanToc: data.tenDanToc ? data.tenDanToc.replace(/\s+/g, ' ').trim() : '',
        status: this.payload.status,
        ghiChu: data.ghiChu,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.DAN_TOC.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['nhomMauPhieu'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
