<template>
  <div>
    <b-form>
      <b-form-group
        class="required"
        label="Mã dân tộc"
        label-for="maDanToc"
      >
        <validation-provider
          v-slot="{ errors }"
          key="maDanToc"
          :rules="{ required: true }"
          name="maDanToc"
        >
          <b-form-input
            id="maDanToc"
            ref="maDanToc"
            v-model="dataForm.maDanToc"
            type="text"
            :class="{required: errors.length > 0}"
            :autofocus="dataForm.id ? false : true"
            placeholder="Nhập mã dân tộc"
          />
          <span class="text-danger">{{ errors[0] }}</span>
          <span class="text-danger">{{ errorTrungMa }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        class="required"
        label="Tên dân tộc"
        label-for="tenDanToc"
      >
        <validation-provider
          v-slot="{ errors }"
          key="tenDanToc"
          :rules="{ required: true }"
          name="tenDanToc"
        >
          <b-form-input
            id="tenQuocTich"
            ref="tenDanToc"
            v-model="dataForm.tenDanToc"
            type="text"
            :class="{required: errors.length > 0}"
            placeholder="Nhập tên dân tộc"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          switch
        />
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Ghi chú"
        label-for="input-1"
      >
        <b-form-textarea
          id="textarea"
          v-model="dataForm.ghiChu"
          v-trim-input
          placeholder="Nhập ghi chú"
          rows="3"
          max-rows="6"
        />
      </b-form-group>
    </b-form>

  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
}
</script>
